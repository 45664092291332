const rules = {
  member: {
    static: ["contact-modal:edit"],
    dynamic: {
      "ticket-options:createTask": (data) => {
        return data.userId === data.ticketUserId;
      },
    },
  },
  system: {
    static: [
      "plan-manager:create",
      "plan-manager:update",
      "plan-manager:view",
      "plan-manager:show:all",
      "companies-manager:create",
      "companies-manager:update",
      "companies-manager:view",
      "companies-manager:show:all",
      "drawer-system-items:view",
      "ticket-options:deleteTicket",
      "ticket-options:transferWhatsapp",
      "user-modal:editProfile",
      // "user-modal:editQueues",
      "contacts-page:deleteContact",
    ],
  },
  owner: {
    static: [
      "drawer-admin-items:view",
      "tickets-manager:showall",
      "user-modal:editProfile",
      "user-modal:editQueues",
      "ticket-options:deleteTicket",
      "ticket-options:createTask",
      "ticket-options:transferWhatsapp",
      "contacts-page:deleteContact",
      "contact-modal:edit",
      "queue:owner",
      "kanban:edit",
    ],
  },
  admin: {
    static: [
      "drawer-admin-items:view",
      "tickets-manager:showall",
      "user-modal:editProfile",
      "user-modal:editQueues",
      "ticket-options:deleteTicket",
      "ticket-options:createTask",
      "ticket-options:transferWhatsapp",
      "contacts-page:deleteContact",
      "contact-modal:edit",
      "kanban:edit",
    ],
  },
  supervisor: {
    static: [
      "user-modal:editProfile",
      "drawer-supervisor-items:view",
      "kanban:edit",
      "contact-modal:edit",
    ],
  },
};

export default rules;
