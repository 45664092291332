import React from "react";
import { Paper, List } from "@material-ui/core";
import { useStyles } from "../../styles";

import { TicketListItem } from "../../../../components/TicketListItem";
import TicketsListSkeleton from "../../../../components/TicketsListSkeleton";
import { i18n } from "../../../../translate/i18n";

export function TicketList({ loadMore, type, list, onAcceptTicket }) {
  const [isLoading] = React.useState(false);
  const [hasMore] = React.useState(false);
  const classes = useStyles();
  const handleScroll = (e) => {
    if (!hasMore || isLoading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      e.currentTarget.scrollTop = scrollTop - 100;
      loadMore(type);
    }
  };
  return (
    <Paper className={classes.ticketsListWrapper}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {list.length === 0 && !isLoading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {list.map((ticket) => (
                <TicketListItem
                  ticket={ticket}
                  key={ticket.id}
                  onAcceptTicket={onAcceptTicket}
                />
              ))}
            </>
          )}
          {isLoading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
}
