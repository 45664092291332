import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Typography,
  Card,
  CardContent,
  Box,
  makeStyles,
  Button,
  CardActions,
  Chip,
} from "@material-ui/core";
import { useTickets } from "../../context/Tickets";
import { useAuthUser } from "../../context/Auth";
import { i18n } from "../../translate/i18n";
import {
  format,
  parseISO,
  isToday,
  isFuture,
  isPast,
  isAfter,
  isBefore,
} from "date-fns";
import Confirm from "../../components/ConfirmationModal";
import { Check, DoneAll, Warning, WatchLater } from "@material-ui/icons";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(() => ({
  ticketQueueColor: {
    width: "8px",
    height: "100%",
  },
  button: {
    cursor: "pointer",
  },
}));

export function TaskItem({ item, onRemove, onClickEdit, onChange }) {
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user } = useAuthUser();
  const { getTicketById } = useTickets();
  const classes = useStyles();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
      data: { type: "Item", item },
      disabled:
        !["admin", "owner"].includes(user.profile) && item.userId !== user.id,
      transition: {
        duration: 100,
        easing: "ease",
      },
      animateLayoutChanges: () => false,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleCompleteTask = async () => {
    setLoading(true);
    try {
      const { data } = await api.patch(`/tasks/${item.id}`, {
        completed: true,
      });

      if (onChange) onChange(data);

      toast.success(i18n.t("kanban.task.complete_success"));
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const STATUS = {
    onTime: {
      color: "#5c7c5c", // Verde acinzentado
      icon: <Check style={{ color: "#5c7c5c" }} />,
      label: i18n.t("kanban.task.status.onTime"),
    },
    dueToday: {
      color: "#a89b66", // Amarelo acinzentado
      icon: <Warning style={{ color: "#a89b66" }} />,
      label: i18n.t("kanban.task.status.dueToday"),
    },
    overdue: {
      color: "#9b5c5c", // Vermelho acinzentado
      icon: <WatchLater style={{ color: "#9b5c5c" }} />,
      label: i18n.t("kanban.task.status.overdue"),
    },
    completed: {
      color: "#868e96", // Cinza neutro (um pouco mais claro)
      icon: <DoneAll style={{ color: "#868e96" }} />,
      label: i18n.t("kanban.task.status.completed"),
    },
  };

  const getStatus = () => {
    if (item.completed) return STATUS.completed;
    const today = new Date();

    const due = new Date(item.due);

    if (isToday(due) && isBefore(today, due)) return STATUS.dueToday;

    if (isToday(due) && isAfter(today, due)) return STATUS.overdue;

    if (isPast(due)) return STATUS.overdue;

    if (isFuture(due)) return STATUS.onTime;
  };

  return (
    <Box display={"flex"} ref={setNodeRef} style={style}>
      <Confirm
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        title={i18n.t("kanban.item.remove")}
        children={i18n.t("kanban.item.remove_message")}
        onConfirm={() => onRemove(item)}
      />

      <Box width={"100%"}>
        <Card
          style={{
            backgroundColor: getStatus().color,
          }}
        >
          <CardContent
            className={classes.button}
            {...attributes}
            {...listeners}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Box>
                <Typography variant={"h6"}>
                  {item.name} #{item.id}
                </Typography>
                <Typography variant={"caption"}>{item.description}</Typography>
              </Box>
              <Typography
                variant={"caption"}
              >{`Ticket #${item.ticketId || "N/A"}`}</Typography>
              <Typography variant={"caption"}>
                Status:
                <Chip
                  size={"small"}
                  style={{
                    color: getStatus().color,
                  }}
                  label={getStatus().label || "N/A"}
                />
              </Typography>
              <Typography variant={"caption"}>
                {i18n.t("kanban.task.due")}:{" "}
                {isToday(new Date(item.due)) ? (
                  <>{format(parseISO(item.due), "p")}</>
                ) : (
                  <>{format(parseISO(item.due), "Pp")}</>
                )}
              </Typography>
            </Box>
          </CardContent>

          <CardActions>
            <Button
              size={"small"}
              color={"secondary"}
              disabled={loading}
              variant={"contained"}
              onClick={() => setConfirmDialog(true)}
            >
              {i18n.t("kanban.task.remove")}
            </Button>
            {item.ticketId && (
              <Button
                size={"small"}
                color={"primary"}
                variant={"contained"}
                onClick={() => getTicketById(item.ticketId)}
              >
                {i18n.t("kanban.item.ticket")}
              </Button>
            )}
            {!item.completed && (
              <Button
                disabled={loading}
                size={"small"}
                color={"primary"}
                variant={"contained"}
                onClick={onClickEdit}
              >
                {i18n.t("kanban.task.edit")}
              </Button>
            )}
            {!item.completed && (
              <Button
                size={"small"}
                color={"primary"}
                disabled={loading}
                variant={"contained"}
                onClick={handleCompleteTask}
              >
                {i18n.t("kanban.task.complete")}
              </Button>
            )}
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}
