import React from "react";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { useTickets } from "../../context/Tickets";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const TaskSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().min(3, "Nome muito curto").required("Obrigatório"),
  description: Yup.string()
    .min(3, "Descrição muito curta")
    .required("Obrigatório"),
  due: Yup.date().required("Obrigatório"),
  message: Yup.string(),
  ticketId: Yup.string(),
  interval: Yup.number().min(0).max(24),
});

export function TaskModal({ value, handleClose, onChange }) {
  const { getAllTickets } = useTickets();
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      id: value?.id || "",
      ticketId: value?.ticketId || "",
      due: value?.due ? format(parseISO(value.due), "yyyy-MM-dd'T'HH:mm") : "",
      name: value?.name || "",
      description: value?.description || "",
      message: value?.message || "",
      interval: value?.reminderIntervalHours || 0,
    },
    resolver: yupResolver(TaskSchema),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      values.name = values.name.trim();
      if (values.id) {
        const { data } = await api.patch(`/tasks/${values.id}`, values);
        if (onChange) onChange(data);
      } else {
        const { data } = await api.post(`/tasks`, values);
        if (onChange) onChange(data);
      }
      setLoading(false);
      toast.success(i18n.t("taskModal.success"));
      handleClose();
      reset();
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  });

  return (
    <div className={classes.root}>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {watch("id")
            ? `${i18n.t("taskModal.title.edit")}`
            : `${i18n.t("taskModal.title.add")}`}
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent dividers>
            <div className={classes.multFieldLine}>
              <TextField
                label={i18n.t("taskModal.form.name")}
                name="name"
                error={!!errors.name}
                helpertext={errors.name?.message || ""}
                variant="outlined"
                margin="dense"
                fullWidth
                {...register("name")}
              />
            </div>
            <div className={classes.multFieldLine}>
              <TextField
                label={i18n.t("taskModal.form.description")}
                name="description"
                error={!!errors.description}
                helpertext={errors.description?.message || ""}
                multiline
                variant="outlined"
                margin="dense"
                fullWidth
                {...register("description")}
              />
            </div>
            <div className={classes.multFieldLine}>
              <TextField
                label={i18n.t("taskModal.form.dueDate")}
                name="due"
                type={"datetime-local"}
                error={!!errors.due}
                helpertext={errors.due?.message || ""}
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("due")}
              />
            </div>
            <div className={classes.multFieldLine}>
              <TextField
                label={i18n.t("taskModal.form.interval")}
                name="interval"
                type={"number"}
                inputProps={{
                  min: 0,
                  max: 24,
                }}
                error={!!errors.interval}
                helpertext={errors.interval?.message || ""}
                variant="outlined"
                margin="dense"
                fullWidth
                {...register("interval")}
              />
            </div>
            {!watch("id") && (
              <div className={classes.multFieldLine}>
                <Controller
                  control={control}
                  name={"ticketId"}
                  render={({ field, fieldState }) => (
                    <FormControl
                      fullWidth
                      variant={"outlined"}
                      margin={"dense"}
                    >
                      <InputLabel htmlFor={"select-ticket-label"}>
                        {i18n.t("taskModal.form.ticket")}
                      </InputLabel>
                      <Select
                        id={"select-ticket"}
                        variant="outlined"
                        fullWidth
                        labelId="select-ticket-label"
                        value={field.value || ""}
                        error={!!fieldState.error}
                        helpertext={fieldState.error?.message}
                        label={i18n.t("taskModal.form.ticket")}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {getAllTickets().map((ticket) => (
                          <MenuItem key={ticket.id} value={ticket.id}>
                            {ticket.contact.name} - {ticket.contact.number}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            )}
            {!watch("id") && (
              <div className={classes.multFieldLine}>
                <TextField
                  label={i18n.t("taskModal.form.message")}
                  name="message"
                  error={false}
                  helpertext={i18n.t("taskModal.form.helperMessage")}
                  variant="outlined"
                  multiline
                  margin="dense"
                  fullWidth
                  {...register("message")}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              disabled={isLoading}
              color="secondary"
              variant="outlined"
            >
              {i18n.t("taskModal.buttons.cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isLoading}
              className={classes.btnWrapper}
            >
              {watch("id")
                ? `${i18n.t("taskModal.buttons.okEdit")}`
                : `${i18n.t("taskModal.buttons.okAdd")}`}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
