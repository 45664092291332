import React from "react";
import {
  Paper,
  Tabs,
  Tab,
  Badge,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import { MoveToInbox, CheckBox } from "@material-ui/icons";

import TabPanel from "../../../../components/TabPanel";
import TicketsQueueSelect from "../../../../components/TicketsQueueSelect";
import NewTicketModal from "../../../../components/NewTicketModal";

import { TicketList } from "../TicketList";

import { useStyles } from "../../styles";
import { i18n } from "../../../../translate/i18n";
import { useAuthUser } from "../../../../context/Auth";
import { useQueues } from "../../../../context/Queues";
import { useTickets } from "../../../../context/Tickets";

export function TicketManager() {
  const [tab, setTab] = React.useState("open");
  const [tabOpen, setTabOpen] = React.useState("open");
  const [queueIds, setQueueIds] = React.useState([]);
  const [newTicketModalOpen, setNewTicketModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const classes = useStyles();
  const { user } = useAuthUser();
  const { tickets, isLoading, fetchTickets } = useTickets();
  const { queues } = useQueues();

  const filterTickets = () => {
    const value = search.toLowerCase();
    const data = tickets[tabOpen]?.tickets || [];
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    if (!value) return data;
    const result = data.filter((elem) => {
      const userFilter = elem.user
        ? elem.user.name.toLowerCase().indexOf(value) > -1
        : false;

      return (
        elem.contact.name.toLowerCase().indexOf(value) > -1 ||
        elem.contact.number.toLowerCase().indexOf(value) > -1 ||
        elem.lastMessage.toLowerCase().indexOf(value) > -1 ||
        userFilter ||
        elem.tags.filter((tag) => tag.name.toLowerCase().indexOf(value) > -1)
          .length > 0
      );
    });
    return result;
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={(_, value) => setTab(value)}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<MoveToInbox />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<CheckBox />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <TextField
          fullWidth
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
          my={1}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setNewTicketModalOpen(true)}
          >
            {i18n.t("ticketsManager.buttons.newTicket")}
          </Button>
          <TicketsQueueSelect
            userQueues={
              user?.queues.length === 0 && user.profile === "owner"
                ? queues
                : user.queues
            }
            selectedQueueIds={queueIds}
            onChange={(values) => {
              setQueueIds(values);
              fetchTickets(values);
            }}
          />
        </Box>
      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={(_, value) => setTabOpen(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={tickets.open?.count || 0}
                color="primary"
                overlap={"rectangular"}
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                overlap={"rectangular"}
                className={classes.badge}
                badgeContent={tickets.pending?.count || 0}
                color="primary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          {!isLoading && (
            <TicketList
              list={filterTickets()}
              type={tabOpen}
              loadMore={() => {}}
              onAcceptTicket={() => setTabOpen("open")}
            />
          )}
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketList status="closed" list={tickets.closed?.tickets || []} />
      </TabPanel>
    </Paper>
  );
}
