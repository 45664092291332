import React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { DragIndicator, Edit } from "@material-ui/icons";

import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  closestCenter,
  useDroppable,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import axios from "axios";
import { CSS } from "@dnd-kit/utilities";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import { ColumnModal } from "./ColumnModal";
import { KanbanItem } from "./KanbanItem";
import { Can } from "../../components/Can";
import { useAuthUser } from "../../context/Auth";
import { i18n } from "../../translate/i18n";
import { useLocalStorage } from "../../util";
import { toast } from "react-toastify";
import { TicketModal } from "./TicketModal";
import { TaskModal } from "../../components/TaskModal";
import { TaskItem } from "./TaskItem";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflowY: "auto",
    marginBottom: "1px",
    overflowX: "hidden",
  },
  mainPaper: {
    display: "flex",
    height: "fit-content",
    overflowX: "scroll",
    overflowY: "hidden",
  },
  column: {
    width: "100%",
    margin: theme.spacing(1),
  },
  columnTitle: {
    display: "flex",
    height: "auto",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    gap: "6px",
  },
  button: {
    width: "100%",
    display: "flex",
  },
  content: {
    display: "flex",
    height: "100%",
    width: "22vw",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    gap: "10px",
  },
}));

function SortableItem({ openEdit, disabled, ...props }) {
  const { user } = useAuthUser();
  const styles = useStyles();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.id,
      data: props.data,
      disabled: !["owner", "admin"].includes(user.profile),
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div {...props} style={style} ref={setNodeRef}>
      <Paper className={styles.columnTitle}>
        <IconButton disabled={disabled} {...attributes} {...listeners}>
          <DragIndicator />
        </IconButton>
        <Typography variant={"h6"}>{props.data.column.name}</Typography>
        {props.id !== "empty" && (
          <Can
            perform={"kanban:edit"}
            role={user.profile}
            yes={() => (
              <IconButton onClick={openEdit}>
                <Edit />
              </IconButton>
            )}
          />
        )}
      </Paper>
      {props.children}
    </div>
  );
}

function DroppableItem(props) {
  const { setNodeRef } = useDroppable({ id: props.id });

  return <div {...props} ref={setNodeRef} />;
}

export function Kanban() {
  const styles = useStyles();
  const { user } = useAuthUser();
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [throttling, setThrottling] = React.useState(false);
  const [columnModal, setColumnModal] = React.useState({
    visible: false,
    item: null,
  });
  const [kanbanData, setKanbanData] = React.useState([]);
  const [taskModal, setTaskModal] = React.useState({
    item: null,
    visible: false,
  });

  const [ticketModal, setTicketModal] = React.useState(false);

  const columnIds = columns.map((e) => e.id);

  const sensors = useSensors(useSensor(PointerSensor));

  const getColumnItems = (columnId = "empty") => {
    if (columnId === "empty") {
      return kanbanData.filter((e) => !e.columnId);
    }
    return kanbanData.filter((e) => e.columnId === columnId);
  };

  const onChangeTicket = (newTickets) => {
    const data = newTickets.map((ticket) => ({
      id: ticket.id,
      ticket,
      columnId: ticket.kanbanTagId,
      type: "ticket",
    }));

    setKanbanData((items) => [...items, ...data]);
  };

  const onChangeTask = (item) => {
    if (!kanbanData.some((e) => e.id === item.id)) {
      setKanbanData([
        ...kanbanData,
        { id: item.id, task: item, columnId: item.tagId, type: "task" },
      ]);
    }

    const data = kanbanData.map((e) => {
      if (e.id === item.id) {
        e.task = item;
        return e;
      }
      return e;
    });

    setKanbanData(data);
  };

  async function handleRemoveTicket(item) {
    try {
      setLoading(true);
      await api.put("/kanban/ticket", {
        tagId: null,
        isKanban: false,
        ticketId: item.id,
      });
      setKanbanData((items) => {
        return items.filter((e) => e.id !== item.id);
      });
      setLoading(false);
      toast.success(i18n.t("ticketOptionsMenu.kanban.remove_success"));
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  }

  async function handleRemoveTask(item) {
    try {
      setLoading(true);
      await api.delete("/tasks/" + item.id);
      setKanbanData((value) => {
        return value.filter((e) => e.id !== item.id);
      });
      setLoading(false);
      toast.success(i18n.t("kanban.task.remove_success"));
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  }

  function handleColumnModal(data) {
    if (typeof data == "string") {
      setColumns((items) => {
        return items.filter((e) => e.id !== data);
      });
      setKanbanData((values) => {
        return values.map((value) => {
          if (value.columnId === data) {
            value.columnId = null;
          }

          return value;
        });
      });
    }
    if (typeof data !== "string" && data) {
      setColumns((items) => {
        const index = columns.findIndex((e) => e.id === data.id);
        if (index !== -1) {
          items[index] = data;
        } else {
          items.push(data);
        }

        return items;
      });
    }
    setColumnModal({
      visible: false,
      item: null,
    });
  }

  const handleDragEnd = React.useCallback((event) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id === over.id) return;

    const activeData = active.data.current;

    const isActiveAColumn = activeData?.type === "Column";
    const isActiveBColumn = over.data.current.type === "Column";

    if (!isActiveAColumn || !isActiveBColumn) return;

    if (active.id !== over.id) {
      setColumns((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const order = arrayMove(items, oldIndex, newIndex);
        useLocalStorage.setValue(
          "kanban:columns",
          order.map((e) => e.id),
        );
        return order;
      });
    }
  }, []);

  const handleDragOver = React.useCallback(
    (event) => {
      if (throttling) return;
      const { active, over } = event;

      if (!over) return;

      if (active.id === over.id) return;

      const isActiveATask = active.data.current.type === "Item";
      const isOverATask = over.data.current.type === "Item";

      if (!isActiveATask) return;

      const activeId = active.id;
      const overId = over.id;

      setThrottling(true);

      setTimeout(() => {
        setThrottling(false);
      }, 100);

      if (over.data.current.type === "Column") {
        setKanbanData((items) => {
          const activeIndex = items.findIndex((t) => t.id === activeId);
          const activeItem = items[activeIndex];
          const overItem = over.data.current.column;
          if (overItem.id === "empty") {
            activeItem.columnId = null;
          } else {
            activeItem.columnId = overItem.id;
          }

          if (activeItem.type === "task") {
            api
              .patch("/tasks/" + activeItem.id, {
                tagId: activeItem.columnId,
              })
              .catch((err) => toastError(err));
          }
          if (activeItem.type === "ticket") {
            api
              .put("/kanban/ticket", {
                tagId: activeItem.columnId,
                ticketId: activeItem.id,
              })
              .catch((err) => toastError(err));
          }
          return arrayMove(items, activeIndex, 0);
        });
        return;
      }

      if (isActiveATask && isOverATask) {
        setKanbanData((items) => {
          const activeIndex = items.findIndex((t) => t.id === activeId);
          const overIndex = items.findIndex((t) => t.id === overId);
          const activeItem = items[activeIndex];
          const overItem = items[overIndex];

          if (
            activeItem &&
            overItem &&
            activeItem.columnId !== overItem.columnId
          ) {
            activeItem.columnId = overItem.columnId;
            if (activeItem.type === "task") {
              api
                .patch("/tasks/" + activeItem.id, {
                  tagId: activeItem.columnId,
                })
                .catch((err) => toastError(err));
            }
            if (activeItem.type === "ticket") {
              api
                .put("/kanban/ticket", {
                  tagId: activeItem.columnId,
                  ticketId: activeItem.id,
                })
                .catch((err) => toastError(err));
            }
            return arrayMove(items, activeIndex, overIndex - 1);
          }
          return arrayMove(items, activeIndex, overIndex);
        });
        return;
      }
    },
    [throttling],
  );

  React.useEffect(() => {
    const resizeObserverError = (event) => {
      if (
        event.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        event.stopImmediatePropagation();
      }
    };
    window.addEventListener("error", resizeObserverError);
    return () => {
      window.removeEventListener("error", resizeObserverError);
    };
  }, []);

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    async function fetchTickets() {
      try {
        const { data } = await api.get("/kanban", {
          cancelToken: source.token,
        });
        if (!data) return;

        const order = useLocalStorage.getValue("kanban:columns") || [];

        const ids = data.columns.map((e) => e.id);

        const reorder = order
          .map((id) => ids.find((item) => item === id))
          .filter((item) => item);

        const unorderedData = ids.filter((id) => !order.includes(id));

        const orderColumnIds = [...reorder, ...unorderedData];

        setColumns(
          orderColumnIds.map((e) =>
            data.columns.find((item) => item.id === e),
          ) || [],
        );

        const items = [
          ...data.tickets.map((ticket) => ({
            type: "ticket",
            id: ticket.id,
            ticket,
            columnId: ticket.kanbanTagId,
          })),
          ...data.tasks.map((task) => ({
            type: "task",
            id: task.id,
            task,
            columnId: task.tagId,
          })),
        ];

        setKanbanData(items);
        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) return;
        toastError(err);
      }
    }

    fetchTickets();

    return () => {
      source.cancel();
    };
  }, [taskModal]);

  if (isLoading) {
    return (
      <div className={styles.mainPaper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <MainContainer contentWrapperClassName={styles.contentWrapper}>
      {columnModal.visible && (
        <ColumnModal handleClose={handleColumnModal} item={columnModal.item} />
      )}
      {ticketModal && (
        <TicketModal
          handleClose={() => setTicketModal(false)}
          onTickets={onChangeTicket}
        />
      )}
      {taskModal.visible && (
        <TaskModal
          value={taskModal.item}
          onChange={onChangeTask}
          handleClose={() =>
            setTaskModal({
              visible: false,
              item: null,
            })
          }
        />
      )}
      <MainHeader>
        <Title>Kanban</Title>
        <MainHeaderButtonsWrapper>
          <Can
            perform={"kanban:edit"}
            role={user.profile}
            yes={() => (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setTaskModal({
                      visible: true,
                    })
                  }
                >
                  {i18n.t("kanban.task.add")}
                </Button>
              </>
            )}
          />
          <Can
            perform={"kanban:edit"}
            role={user.profile}
            yes={() => (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setTicketModal(true)}
                >
                  {i18n.t("kanban.ticket.add")}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setColumnModal({
                      visible: true,
                      item: null,
                    })
                  }
                >
                  {i18n.t("kanban.column")}
                </Button>
              </>
            )}
          />
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <div className={styles.mainPaper}>
        <DndContext
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          sensors={sensors}
          collisionDetection={closestCenter}
        >
          <SortableContext
            items={columnIds}
            strategy={horizontalListSortingStrategy}
            shouldRecomputeLayout={false}
          >
            <SortableItem
              id={"empty"}
              disabled
              data={{
                type: "Column",
                column: {
                  id: "empty",
                  name: i18n.t("kanban.table.inbox"),
                },
              }}
              className={styles.column}
            >
              <SortableContext items={getColumnItems("empty").map((e) => e.id)}>
                <DroppableItem className={styles.content} id={"empty"}>
                  {getColumnItems("empty").map((item) =>
                    item.type === "ticket" ? (
                      <KanbanItem
                        key={item.id}
                        item={item.ticket}
                        onRemove={handleRemoveTicket}
                      />
                    ) : (
                      <TaskItem
                        key={item.id}
                        item={item.task}
                        onRemove={handleRemoveTask}
                        onChange={onChangeTask}
                        onClickEdit={() =>
                          setTaskModal({
                            visible: true,
                            item: item.task,
                          })
                        }
                      />
                    ),
                  )}
                </DroppableItem>
              </SortableContext>
            </SortableItem>
            {columns.map((column) => (
              <SortableItem
                key={column.id}
                id={column.id}
                data={{
                  type: "Column",
                  column: column,
                }}
                className={styles.column}
                openEdit={() =>
                  setColumnModal({
                    visible: true,
                    item: column,
                  })
                }
              >
                <SortableContext
                  items={getColumnItems(column.id).map((item) => item.id)}
                >
                  <DroppableItem className={styles.content} id={column.id}>
                    {getColumnItems(column.id).map((item) =>
                      item.type === "ticket" ? (
                        <KanbanItem
                          key={item.id}
                          item={item.ticket}
                          onRemove={handleRemoveTicket}
                        />
                      ) : (
                        <TaskItem
                          key={item.id}
                          item={item.task}
                          onRemove={handleRemoveTask}
                          onChange={onChangeTask}
                          onClickEdit={() =>
                            setTaskModal({
                              visible: true,
                              item: item.task,
                            })
                          }
                        />
                      ),
                    )}
                  </DroppableItem>
                </SortableContext>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </MainContainer>
  );
}
