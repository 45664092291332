import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useLocalStorage } from "../../util";
import api from "../../services/api";
import { useAuthUser } from "../../context/Auth";
import { socket } from "../../services/socket-io";
import toastError from "../../errors/toastError";

const messages = ["- Otimização da plataforma para melhor desempenho."];

export function WarningDialog({ children }) {
  const [open, setOpen] = React.useState(false);
  const { user } = useAuthUser();
  const handleCloseDialog = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    const controller = new AbortController();
    async function fetchLocalStorage() {
      try {
        const { data } = await api.get("/version", {
          signal: controller.signal,
        });

        const version = useLocalStorage.getValue("version", "");

        if (!version || version !== data.version) {
          useLocalStorage.setValue("version", data.version);
          setOpen(true);
        }
        return;
      } catch (err) {
        return;
      }
    }

    function handleEvents(err) {
      toastError({ response: { data: err } });
    }

    fetchLocalStorage();
    if (user) {
      socket.on(`error:${user.companyId}`, handleEvents);
    }
    return () => {
      controller.abort();
      if (user) {
        socket.off(`error:${user.companyId}`, handleEvents);
      }
    };
  }, [user]);

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth scroll="paper">
        <DialogTitle>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant={"subtitle2"}>
              O sistema foi atualizado!
            </Typography>
            <Typography variant="subtitle2" color={"textSecondary"}>
              (Verifique as conexões)
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant={"subtitle2"}>
              Mudanças e/ou correções feitas:
            </Typography>
            {messages.map((e, index) => (
              <Typography key={index} variant={"subtitle3"}>
                {e}
              </Typography>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handleCloseDialog}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
}
