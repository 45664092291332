import React, { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Switch,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { VisibilityOff, Visibility } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const CompanyRegistration = ({ open, onClose, companyId }) => {
  const classes = useStyles();

  const CreateCompanySchema = Yup.object().shape({
    name: Yup.string().required(`${i18n.t("company.companyModal.required")}`),
    email: Yup.string()
      .email(`${i18n.t("company.companyModal.invalidEmail")}`)
      .required(`${i18n.t("company.companyModal.required")}`),
    password: Yup.string()
      .required(`${i18n.t("company.companyModal.required")}`)
      .min(5, "Too Short!")
      .max(50, "Too Long!"),
    planId: Yup.string()
      .uuid()
      .required(`${i18n.t("company.companyModal.required")}`),
    companyName: Yup.string()
      .min(2)
      .required(i18n.t("company.companyModal.required")),
    isActive: Yup.boolean(),
  });

  const UpdateCompanySchema = CreateCompanySchema.omit([
    "email",
    "password",
    "name",
  ]);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      email: "",
      password: "",
      planId: "",
      companyName: "",
      isActive: true,
    },
    resolver: yupResolver(
      companyId ? UpdateCompanySchema : CreateCompanySchema,
    ),
  });

  const handleClose = React.useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  React.useEffect(() => {
    let unmounted = false;

    const fetchMain = async () => {
      try {
        if (unmounted) return;

        const plans_results = await api.get("/plans/list");
        setPlans(plans_results.data.plans);

        if (!companyId) return;

        const companies_results = await api.get(`/companies/${companyId}`);

        reset({
          ...companies_results.data,
          companyName: companies_results.data.name,
        });
      } catch (err) {
        toastError(err);
      }
    };

    fetchMain().finally(() => setLoading(false));

    return () => {
      unmounted = true;
    };
  }, [companyId, reset, open]);

  // useEffect(() => {
  //   let unmounted = false;

  //   async function fetchCompany() {
  //     try {
  //       if (unmounted) return;
  //       if (!companyId) return;
  //       const { data } = await api.get(`/companies/${companyId}`);
  //       reset({ ...data, companyName: data.name });
  //     } catch (err) {
  //       toastError(err);
  //       handleClose();
  //     }
  //   }

  //   async function fetchPlans() {
  //     try {
  //       if (unmounted) return;
  //       const { data } = await api.get("/plans/list");
  //       setPlans(data.plans);
  //     } catch (err) {
  //       toastError(err);
  //     }
  //   }

  //   async function main() {
  //     fetchCompany();
  //     fetchPlans();
  //   }

  //   main().finally(() => setLoading(false));

  //   return () => {
  //     unmounted = true;
  //   };
  // }, [companyId, reset, open]);

  const onSubmit = handleSubmit(async (values) => {
    const companyData = { ...values };

    try {
      if (companyId) {
        await api.put(`/companies/update/${companyId}`, companyData);
        toast.success(i18n.t("company.update"));
        return;
      }
      await api.post("/companies/create", companyData);
      toast.success(i18n.t("company.createdAt"));
      handleClose();
      return;
    } catch (err) {
      handleClose();
      toastError(err);
    }
  });

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {companyId
            ? `${i18n.t("company.companyModal.titleEdit")}`
            : `${i18n.t("company.companyModal.titleAdd")}`}
        </DialogTitle>
        <form onSubmit={onSubmit}>
          {isLoading ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <DialogContent dividers>
              <div className={classes.multFieldLine}>
                <TextField
                  name={"companyName"}
                  variant={"outlined"}
                  margin="dense"
                  label={i18n.t("company.companyModal.companyName")}
                  error={errors.companyName}
                  helperText={
                    errors.companyName ? errors.companyName.message : ""
                  }
                  fullWidth
                  {...register("companyName")}
                />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  margin="dense"
                >
                  <InputLabel>{i18n.t("company.companyModal.plan")}</InputLabel>
                  <Select
                    label={i18n.t("company.companyModal.plan")}
                    required
                    value={watch("planId")}
                    {...register("planId")}
                  >
                    {plans.map((plan) => (
                      <MenuItem key={plan.id} value={plan.id}>
                        {plan.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {companyId && (
                <FormControlLabel
                  label={i18n.t("company.companyModal.status")}
                  control={
                    <Switch
                      name="isActive"
                      checked={watch("isActive")}
                      {...register("isActive")}
                    />
                  }
                />
              )}
              <Divider />
              {!companyId && (
                <>
                  <TextField
                    name="name"
                    variant="outlined"
                    margin="dense"
                    label={i18n.t("company.companyModal.name")}
                    error={errors.name}
                    helperText={errors.name ? errors.name.message : ""}
                    fullWidth
                    {...register("name")}
                  />
                  <TextField
                    label={i18n.t("company.companyModal.email")}
                    name="email"
                    error={errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    {...register("email")}
                  />
                  <TextField
                    name="password"
                    variant="outlined"
                    margin="dense"
                    label={i18n.t("company.companyModal.password")}
                    error={errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((e) => !e)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    {...register("password")}
                  />
                </>
              )}
            </DialogContent>
          )}
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              disabled={isSubmitting}
              variant="outlined"
            >
              {i18n.t("userModal.buttons.cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnWrapper}
              disabled={isSubmitting}
            >
              {companyId
                ? `${i18n.t("userModal.buttons.okEdit")}`
                : `${i18n.t("userModal.buttons.okAdd")}`}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CompanyRegistration;
