import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import ConfirmationModal from "../../../../components/ConfirmationModal";
import TransferTicketModal from "../../../../components/TransferTicketModal";

import { Can } from "../../../../components/Can";

import { i18n } from "../../../../translate/i18n";

import { useAuthUser } from "../../../../context/Auth";

import api from "../../../../services/api";

import toastError from "../../../../errors/toastError";
import { toast } from "react-toastify";
import { useTickets } from "../../../../context/Tickets";
import { TaskModal } from "../../../../components/TaskModal";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const isMounted = useRef(true);

  const history = useHistory();
  const { handleUpdateTicketKanban } = useTickets();
  const { user } = useAuthUser();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
      history.push("/tickets");
      toast.success(i18n.t("tickets.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationOpen(true);
    handleClose();
  };

  const handleOpenTransferModal = () => {
    setTransferTicketModalOpen(true);
    handleClose();
  };

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenTransferModal}>
          {i18n.t("ticketOptionsMenu.transfer")}
        </MenuItem>
        {ticket.isKanban ? (
          <Can
            role={user.profile}
            perform="kanban:edit"
            no={() => (
              <MenuItem onClick={() => history.push("/kanban")}>
                {i18n.t("ticketOptionsMenu.kanban.view")}
              </MenuItem>
            )}
            yes={() => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleUpdateTicketKanban();
                }}
              >
                {i18n.t("ticketOptionsMenu.kanban.remove")}
              </MenuItem>
            )}
          />
        ) : (
          <Can
            role={user.profile}
            perform="kanban:edit"
            yes={() => (
              <MenuItem onClick={handleUpdateTicketKanban}>
                {i18n.t("ticketOptionsMenu.kanban.add")}
              </MenuItem>
            )}
          />
        )}
        <Can
          role={user.profile}
          perform="ticket-options:createTask"
          data={{
            ticketUserId: ticket.userId,
            userId: user.id,
          }}
          yes={() => (
            <MenuItem onClick={() => setTaskModalOpen(true)}>
              {i18n.t("ticketOptionsMenu.createTask")}
            </MenuItem>
          )}
        />
        <Can
          role={user.profile}
          perform="ticket-options:deleteTicket"
          yes={() => (
            <MenuItem onClick={handleOpenConfirmationModal}>
              {i18n.t("ticketOptionsMenu.delete")}
            </MenuItem>
          )}
        />
      </Menu>
      <ConfirmationModal
        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
          ticket.id
        } ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
          ticket.contact.name
        }?`}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteTicket}
      >
        {i18n.t("ticketOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <TransferTicketModal
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticketid={ticket.id}
        ticketWhatsappId={ticket.whatsappId}
      />
      {taskModalOpen && (
        <TaskModal
          value={{
            ticketId: ticket.id,
          }}
          ticketId={ticket.id}
          handleClose={() => setTaskModalOpen(false)}
        />
      )}
    </>
  );
};

export default TicketOptionsMenu;
