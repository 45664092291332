import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAuthUser } from "../../context/Auth";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useWhatsApps } from "../../context/WhatsApps";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório"),
  contactId: Yup.string().uuid().required("Obrigatório"),
  connectionId: Yup.string().uuid().required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, reload }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuthUser();
  const [searchParam, setSearchParam] = useState("");
  const { whatsapps } = useWhatsApps();
  const [isLoading, setLoading] = useState("");

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    connectionId: "",
    sentAt: "",
  };

  const [contacts, setContacts] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialState,
    resolver: yupResolver(ScheduleSchema),
  });

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true);

      if (!open || (searchParam.length !== 0 && searchParam.length < 3))
        return setLoading(false);

      const { data: contactData } = await api.get("/contacts", {
        params: {
          searchParam,
        },
      });

      setContacts(contactData.contacts);

      setLoading(false);
      if (!scheduleId) return setLoading(false);

      const { data } = await api.get(`/schedules/${scheduleId}`);

      reset({
        ...data,
        sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
      });
      setLoading(true);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [scheduleId, contactId, open, reset, searchParam]);

  const renderOption = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const renderOptionLabel = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const handleClose = () => {
    onClose();
    reset(initialState);
  };

  const handleSaveSchedule = async (values) => {
    const scheduleData = { ...values, userId: user.id };
    try {
      scheduleData.sendAt = new Date(scheduleData.sendAt).toISOString();

      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
      } else {
        await api.post("/schedules", scheduleData);
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload == "function") {
        reload();
      }
      reset(initialState);
      history.push("/schedules");
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {watch("status") === "ERROR"
            ? "Erro de Envio"
            : "Mensagem " +
              `${watch("status") ? i18n.t("schedules.status." + watch("status")) : ""}`}
        </DialogTitle>
        <form onSubmit={handleSubmit(handleSaveSchedule)}>
          <DialogContent dividers>
            <div className={classes.multFieldLine}>
              <Controller
                control={control}
                name={"contactId"}
                variant="outlined"
                fullWidth
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    value={contacts.find((c) => c.id === field.value) || null}
                    options={contacts}
                    onChange={(_, value) => {
                      field.onChange(value?.id || "");
                    }}
                    getOptionLabel={renderOptionLabel}
                    renderOption={renderOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.contactId}
                        helperText={errors.contactId?.message}
                        onChange={(e) => setSearchParam(e.target.value)}
                        variant="outlined"
                        placeholder="Contato"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </div>
            <br />
            <div className={classes.multFieldLine}>
              <Controller
                control={control}
                name={"connectionId"}
                render={({ field }) => (
                  <FormControl fullWidth variant={"outlined"}>
                    <InputLabel id={"connection-select-label"}>
                      Connection
                    </InputLabel>
                    <Select
                      labelId="connection-select-label"
                      id="connection"
                      value={field.value || ""}
                      fullWidth
                      onChange={(event) => field.onChange(event.target.value)}
                    >
                      {whatsapps.map((wpp) => (
                        <MenuItem key={wpp.id} value={wpp.id}>
                          {wpp.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <br />
            <div className={classes.multFieldLine}>
              <TextField
                multiline={true}
                label={i18n.t("scheduleModal.form.body")}
                error={!!errors.body}
                helperText={errors.body?.message}
                variant="outlined"
                value={watch("body")}
                margin="dense"
                minRows={9}
                fullWidth
                {...register("body")}
              />
            </div>
            <br />
            <div className={classes.multFieldLine}>
              <TextField
                label={i18n.t("scheduleModal.form.sendAt")}
                type="datetime-local"
                name="sendAt"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.sendAt}
                helperText={errors.sendAt?.message}
                variant="outlined"
                fullWidth
                {...register("sendAt")}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              disabled={isSubmitting}
              variant="outlined"
            >
              {i18n.t("scheduleModal.buttons.cancel")}
            </Button>
            {(watch("status") === "PENDING" || !scheduleId) && (
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.btnWrapper}
              >
                {scheduleId
                  ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                  : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
