import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { socket } from "../../services/socket-io";
import { useAuthUser } from "../../context/Auth";
import { useHistory } from "react-router-dom";

export function TaskNotificationDialog(props) {
  const { user } = useAuthUser();
  const [notification, setNotification] = React.useState(null);
  const history = useHistory();

  const handleClose = () => {
    setNotification(null);
  };

  const handleGotoKanban = () => {
    history.push("/kanban");
    handleClose();
  };

  React.useEffect(() => {
    const handleNotification = (data) => {
      const { event, notification } = data;
      if (event === "create" && notification.event === "task.reminder")
        setNotification(notification);
    };

    socket.on(`notification:${user?.id}`, handleNotification);

    return () => {
      socket.off(`notification:${user?.id}`, handleNotification);
    };
  }, [user?.id]);

  return (
    <>
      <Dialog open={!!notification} maxWidth="xs" fullWidth scroll="paper">
        <DialogTitle>
          <Box>
            <Typography>{i18n.t("notifications.reminder.title")}</Typography>
            <Typography variant={"caption"}>
              {i18n.t("notifications.reminder.body")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {notification && (
              <>
                {notification?.metadata?.taskName || ""} #
                {notification?.metadata?.taskId}
              </>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleGotoKanban}>
            {i18n.t("notifications.reminder.buttons.kanban")}
          </Button>
          <Button variant={"contained"} color={"primary"} onClick={handleClose}>
            {i18n.t("notifications.reminder.buttons.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      {props.children}
    </>
  );
}
